@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: black;
}

.app-wrapper {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, #131313, #2b2b2b);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Josefin Sans', sans-serif;
}

.main-wrapper {
  position: relative;
  width: 90vw;
  height: 80vh;
  background: transparent;
  border: 2.5px solid white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -54px 51px 38px -4px rgba(0, 0, 0, 0.58);
}

section {
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 1rem 3rem;
}

nav ul {
  list-style: none;
  display: flex;
}

nav ul li a {
  text-transform: uppercase;
  padding: 0 20px;
  text-decoration: none;
  color: white;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 70%;
}

.menu a {
  text-decoration: none;
  color: #000000de;
}

.content > div {
  padding: 0 3rem;
}

.content .block1 p {
  color: grey;
}

.line {
  width: 3rem;
  height: 3px;
  color: white;
}

.content .contact {
  border-radius: 20px;
  border: 1px solid rgb(222, 226, 232);
  background: transparent;
  color: rgb(222, 226, 232);
  text-decoration: none;
  padding: 0.3rem 2rem;
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
}

.shower img {
  width: 200px;
}

footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.whoisthemaster {
  text-transform: uppercase;
  transform: rotate(-90deg);
  color: white;
  font-size: 11px;
  margin-top: -2rem;
  margin-left: 2rem;
}

.icons * {
  padding: 1rem 2.5rem;
}

.burger {
  display: none;
  position: relative;
  margin-right: 3rem;
  margin-top: 1rem;
}

.burger > .first {
  position: absolute;
  top: 0;
  height: 4px;
  width: 4rem;
  background: white;
}

.burger > .second {
  position: absolute;
  top: 10px;
  height: 4px;
  width: 4rem;
  background: white;
}

.burger > .third {
  position: absolute;
  top: 20px;
  height: 4px;
  width: 4rem;
  background: white;
}

#burger.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 5rem;
  background: grey;
}

.menubtn {
  display: none;
}

.menuwrapper {
  display: none;
}

.Exude-Logo {
  margin: 0 1rem;
}

@media (max-width: 850px) {
  .menuwrapper {
    display: unset;
  }
  nav {
    display: none;
  }
  nav ul {
    display: flex;
    flex-direction: column;
  }
  .burger {
    display: flex;
  }
  img {
    display: none;
  }
  .content > div {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .block1 p {
    margin-left: 5rem;
    text-align: right;
    color: lightgray;
  }
  .block1 h1 {
    display: none;
  }
  button {
    margin-top: 0.5rem;
    height: 3rem !important;
    border-radius: 35px !important;
    color: white !important;
  }
  .whoisthemaster {
    position: absolute;
    right: -0.5rem;
    bottom: 9rem;
  }
  .app-wrapper {
    background: url(/static/media/xallet.95c1b79a.png);
    background-repeat: no-repeat;
    background-size: 200px;
    background-position: center;
    height: 90vh;
  }
  .main-wrapper {
    background-color: rgba(255, 255, 255, 0.009);
  }
  .menubtn {
    display: unset;
    color: white !important;
    border-radius: 0 !important;
  }
  .content .block1 p {
    color: white;
    margin-bottom: 16rem;
    font-size: 20px;
  }
  .icons a {
    padding: 0;
  }
}

.mobilediv {
  display: none;
}

@-webkit-keyframes arrow {
  0% {
    top: 1.8rem;
  }
  50% {
    top: 2.5rem;
  }
  100% {
    top: 1.8rem;
  }
}

@keyframes arrow {
  0% {
    top: 1.8rem;
  }
  50% {
    top: 2.5rem;
  }
  100% {
    top: 1.8rem;
  }
}

@media (max-width: 992px) {
  .mobilediv {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin: 0 2rem;
  }

  .mobilediv .single {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 1.5rem 0;
  }

  .mobilediv .single img {
    display: flex;
    width: 300px;
  }

  .mobilediv .single p {
    font-size: 17px;
    font-style: italic;
  }

  .arrowdiv {
    position: relative;
    width: 100%;
    height: 5rem;
  }

  .mobilediv .arrow svg {
    position: absolute;
    -webkit-animation: arrow 2s ease-in-out infinite;
            animation: arrow 2s ease-in-out infinite;
  }
}

.aboutinfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin: 0 2rem;
}

.aboutinfo .single {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
}

.aboutinfo .single.second {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
}

.aboutinfo .single.third {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
}

.aboutinfo .detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
}

.aboutinfo .single img {
  display: flex;
  width: 300px;
}

.aboutinfo .single p {
  font-size: 17px;
  font-style: italic;
}

.banner {
  width: 100%;
  height: 25rem;
  background: url(/static/media/background.9ad5b0b4.png);
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.banner h1 {
  margin-left: 5rem;
}

.navigationabout {
  width: 8rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.256);
  position: fixed;
}

.navigationabout ul li a {
  font-size: 20px;
}

.daten {
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(90deg, #131313, #2b2b2b);
}

@media (max-width: 992px) {
  .daten {
    display: flex;
    justify-content: center;
    background: black;
  }
}

.impressum {
  color: white;
  margin: 1rem 2rem;
}

.datenschutz {
  color: white;
  margin: 1rem 2rem;
}

.daten a {
  color: white;
  text-decoration: none;
  margin: 0 1rem;
  font-size: 20px;
  font-weight: 500;
  margin: 10px;
}

